.about {
    display:flex;
    flex-direction: column;
    
   
  
    
}
.row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    height: 75%;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;
    flex-direction: column;
   
    
    
    
  }
  

  .about-top-desc-wrapper{
      max-width: 1000px;
      padding-top: 0;
      padding-bottom: 60px;
      margin-left:150px;
      font: 1em sans-serif;
      display:flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      height: 300px;
      width: 100%;
      
      
      
      
     
  }

  .about-top-desc-container {
    
    display: flex;
    flex-direction: column;
    width: 70vw;
    margin-left: -160px;
    margin-top: 100px;
  }
  
  .about-title {
    font: 1em sans-serif;
    width:100%;

  }

  .about-desc {
    font: 2rem sans-serif;
    line-height: 1.25cm;
    width:100%;
    
  }

  .about-bottom-info-wrapper {
    max-width: 1000px;
    padding-top: 0;
    padding-bottom: 60px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 600px;
    margin-left: 100px;
    
  }


  
  
  .top-pic{
    width:300px;
    height:300px;
    object-fit:cover;
    border-radius:50%;
    margin-left: 100px;
    margin-top: 150px;
    
    
    
  }
  .bottom-contact-container {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:100%;
    margin-top:100px;
  }

  
  .bottom-contact-icons {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 75px;
    margin-top: 100px;
    width:50%;
    height: 400px;
  }

  .bottom-contact-button {
    margin-top: 30px;
    background-color:rgb(0,123,255); 
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    transform: scale(0.95);
    border: 2px solid rgb(0,123,255);
    outline: none;
    
  }

  .bottom-contact-button:hover {
    transform: scale(1.05);
  }
  
  .bottom-contact-icon {
    margin-top: 30px;
    cursor: pointer;
  }

  .bottom-contact-icon:hover {
    color:gray;
    transform: scale(1.1);
  }
  
  .skill-container{
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   width: 100%;
   margin-top: 100px;
   margin-left:70px;

    
  }

  .skill-header {
    padding-bottom: 20px;
    font: 1em sans-serif;
  }


  .skill-list{
    display:grid;
    list-style: none;
    grid-template-columns: 200px 200px 200px 200px;
    grid-row-gap: 30px;
    grid-column-gap: 10px;
 
  }



  .skill-text{
    font-size: 1em;
  }

  .skill-item-container{
    display:flex;
    flex-direction: row;
    align-items: center;
    
  }



  .skill-icon {
    height: 50px;
    width: 50px;
  }

  .skill-name {
    font-size: 2rem;
    font: sans-serif;
  }
  

  @media screen and (max-width: 1100px) {
    .row{
      margin-left: 70px;
      flex-direction: column;
      align-items: center;
      justify-content:space-evenly;
      height: 50%;
      margin-top:0px;
    }
    
    .about-top-pic-container {
        width: 100%;
        display:flex;
        justify-content: center;
    }

    .top-pic{
      width:200px;
      height:200px;
      border-radius:50%;
      margin-right:250px;
      margin-top:100px;
      
    }
    
    .about-desc {
      font: 1.0rem sans-serif;
      width: 125%;
      line-height: 1cm;
      
    }
  
    .skill-icon {
      height: 40px;
      width: 40px;
    }
  
    .skill-name {
      font-size: 1.25rem;
      font: sans-serif;
      display:none;
    }
    
    .skill-list{
     
      grid-template-columns: 100px 100px 100px 100px ;
      grid-row-gap: 20px;
      grid-column-gap: 30px;
     
   
    }
    
    .about-top-desc-wrapper {
      flex-direction: column-reverse;
      align-items: center;
      justify-content:center;
      margin-top:-100px;
      margin-left:50px;
      height: 75px;
      
    }
    
    .about-top-desc-container {
      margin-top:30px;
    }
    
    .bottom-contact-container {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      margin-top:100px;
      height: 75px;
      width:auto;
    }
    
    .bottom-contact-icons{
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 400px;
    }
    
    .skill-container {
      margin-top: 100px;
      margin-left:-200px;
      width: 70vw;
    }
    
    
   
  
    .bottom-contact-icon{
      width:100px;
    }
  
  
  }

 