.contact {
    display: flex;
    flex-direction: column;
    background-color: rgb(226, 221, 221);
   
    
    
}

.contact-page-title {
    font-family: sans-serif;
}

.contact-page-container {
    display: flex;
    flex-direction: row;
    margin-top: 120px;
}

.contact-page-container-right {
    display:flex;
    flex-direction: column;
    width:20%;
    align-items: center;
    margin-left: 250px;
   
}

.contact-page-container-left {
    width:50%;
    margin-left: 200px;
    border-left :10px solid black;
    padding-left: 30px;
    font: 1.75em sans-serif;
    text-align: left;
    line-height: 1.25cm;
    
    
}

@media screen and (max-width: 1100px) {
    
    .contact-page-container-left {
        width:100%;
        margin-left: -45px;
        justify-content: flex-start;
    }
    
    .contact-page-info {
        font-size:1rem;
        width:100%;
    }
    .contact-page-container {
        margin-left: 70px;
        flex-direction: column;
        align-items: center;
        justify-content:flex-start;

    }
    .contact-page-container-right {
        width:100%;
        flex-direction: row;
        justify-content: space-evenly;
        margin-left: -30px;
        
       
    }
}