.project {
    display:flex;
    flex-direction: column;
    justify-content: center;
    
    
   
}

.project-context {
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.project-header {
    margin-left: 275px;
    margin-top: 100px;
}

.project-title {
    font: 3em sans-serif;
    display:flex;
    justify-content: start;
    width: 100%;
    
}

.project-card {
    margin-left: 100px;
}


@media screen and (max-width: 1100px) {
    .project-header {
        margin-left: 10px;
        margin-top: 200px;
    }
    
    .project-card {
        margin-left: 0px;
    }
}